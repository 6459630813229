@keyframes backgroundColours {
  0% { background-position: 0 51% }
  50% { background-position: 100% 50% }
  100% { background-position: 0 51% }
}

@mixin colour-gradient-animated {
  background: linear-gradient(239deg, #4BC114, #1499C1, #E90B1F, #0E16AF, #FED631);
  background-size: 1000% 1000%;
  animation: backgroundColours 30s ease infinite;
}