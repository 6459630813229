// Fonts
@import url(https://fonts.googleapis.com/icon?family=Material+Icons|Lato:100);
// Variables
@import "variables";
@import "animations";
@import "login";
@import "error";

body, html {
  height: 100%;
}

nav .brand-logo {
  max-height: 64px;
  padding: 10px 10px 10px;
}

.welcome-msg {
  h2 {
    font-weight: 200;
  }
  img {
    max-height: 400px;
  }
}

.page-content {
  min-height: 75vh;
}

.token {
  word-wrap: break-word;
}

.terminal-view {
  display: block;
  padding: 1em;
  border-radius: 6px;
  border-top: #E1E1E1 22px solid;
  word-wrap: break-word;
}

.terminal-view .close-btn-wrapper {
  position: relative;
  width: 0;
  height: 0;
}

.terminal-view .close-btn-wrapper > .close-btn {
  display: block;
  position: absolute;
  left: -0.5em;
  top: -32px;
  background: rgb(252, 99, 93);
  border-radius: 100%;
  width: 13px;
  height: 13px;
}

.terminal-view .close-btn-wrapper > .min-btn {
  display: block;
  position: absolute;
  left: 0.9em;
  top: -32px;
  background: rgb(254, 192, 65);
  border-radius: 100%;
  width: 13px;
  height: 13px;
}

.terminal-view .close-btn-wrapper > .fullscreen-btn {
  display: block;
  position: absolute;
  left: 2.3em;
  top: -32px;
  background: rgb(53, 205, 75);
  border-radius: 100%;
  width: 13px;
  height: 13px;
}

.help {
  margin-top: 0;
  color: gray;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.input-field:hover > .help, .col:hover > .help {
  color: rgba(0, 0, 0, 0.87);
}

.page-footer {
  background: #729EA1 !important;
}

.preview-page  {
  img {

  }
}

@import "gallery";