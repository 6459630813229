.error-page {
  body, html {
    height: 100%;
  }
  height: 100%;
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    color: #B0BEC5;
    display: table;
    font-weight: 100;
    font-family: 'Lato', sans-serif;
    @include colour-gradient-animated;
  }

  .container {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }

  .content {
    text-align: center;
    display: inline-block;
  }

  .title {
    font-size: 72px;
    margin-bottom: 40px;
  }

  img {
    max-height: 250px;
    padding-bottom: 20px;
  }
}
