$lowlight: #0097a7 !default;
$primary: #00bcd4 !default;

.c-scrim {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.541176);
  opacity: 0;
  transition: 200ms ease opacity;
  will-change: opacity;

  &--shown {
    opacity: 1;
  }
}

.c-datepicker {
  min-height: 610px;
  position: fixed;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  background: white;
  border: 0;
  width: 300px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  box-shadow: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
  border-radius: 2px;
  opacity: 0;
  will-change: opacity;
  transition: 200ms ease-in-out opacity, 200ms ease-in-out top;

  &--open {
    opacity: 1;
    top: 50%;
  }
}

.c-datepicker--horizontal {

}

.c-datepicker__header {
  position: relative;
}

.c-datepicker__header-day {
  height: 32px;
  background: $lowlight;
  color: white;
  line-height: 32px;
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 0.3px;
}

.c-datepicker__header::after {
  content: "";
  display: table;
  clear: both;
}

.c-datepicker__header-date {
  background: $primary;
  height: 150px;
  padding: 16px 0;
}

.c-datepicker__month {
}

.rd-month-label {
  height: 56px;
  line-height: 56px;
  font-size: 14px;
  font-weight: 800;
}

.c-datepicker__back, .c-datepicker__next, .c-datepicker__toggle {
  position: absolute;
  border: 0;
  background: white;
  font-family: 'Material Icons';
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" 1;
  font-style: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  width: 56px;
  height: 56px;
  display: inline-block;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.c-datepicker__back {
  left: 0;
}

.c-datepicker__next {
  right: 0;
}

.c-datepicker__back:before {
  content: 'chevron_left';
}

.c-datepicker__next:after {
  content: 'chevron_right';
}

.c-datepicker--show-time:after {
  content: 'access_time';
  color: white;
  visibility: visible;
}

.c-datepicker--show-calendar:after {
  content: 'grid_on';
  color: white;
  visibility: visible;
}

.c-datepicker__header-date span {
  display: block;
  color: white;
  margin: 4px 0;
  transition: opacity 100ms ease-in-out;
}

.c-datepicker__header-date__month {
  cursor: pointer;
  font-size: 24px;
  opacity: 0.6;
}

.c-datepicker__header-date__day {
  cursor: pointer;
  font-size: 64px;
  opacity: 0.6;
}

.c-datepicker__header-date__time {
  font-size: 25px;
  opacity: 0.6;

  > span {
    display: inline-block;
  }
}

.c-datepicker__header-date__hours, .c-datepicker__header-date__minutes {
  cursor: pointer;
}

.c-datepicker--show-time.is-selected ~ .c-datepicker__header .c-datepicker__header-date__time {
  opacity: 1;

  .c-datepicker__header-date__hours, .c-datepicker__header-date__minutes {
    opacity: .6;
    &.active {
      opacity: 1;
    }
  }
}

.c-datepicker--show-calendar.is-selected {
  ~ .c-datepicker__header {
    .c-datepicker__header-date__month, .c-datepicker__header-date__day {
      opacity: 1
    }
  }
}

.modal-btns {
  padding: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.c-datepicker__day-body {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  width: 36px;
  height: 36px;
  cursor: pointer;
  position: relative;

  &:hover {
    /* color: white; */
  }
}

.c-datepicker__day--selected::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,0.05);
}

.c-datepicker__day-body:hover {

}

.c-datepicker__day-head {
  color: rgba(0,0,0,0.54);
  font-size: 12px;
  height: 36px;
}

.c-datepicker__day-head, c-datepicker__day-body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.modal-btns {
  float: right;
}

.c-btn {
  display: inline-block;
  min-width: 56px;
  cursor: pointer;
}

.rd-day-prev-month {
  opacity: 0.1;
  pointer-events: none;
}

.rd-day-next-month {
  opacity: 0.1;
  pointer-events: none;
}

.c-datepicker__calendar {
  height: 300px;
}

.c-datepicker__date {
  position: absolute;
  left: 0;
  right: 0;
}

.c-datepicker__days {
  margin: 10px 20px;
}

.c-datepicker__header-toggle {
  position: absolute;
  top: 50%;
  color: white;
  cursor: pointer;

  i {
    font-size: 26px;
  }
}

.c-datepicker__header-toggle--left {
  left: 20px;
}

.c-datepicker__header-toggle--right {
  right: 20px;
}

.c-datepicker__header-toggle--inactive {
  opacity: 0.2;
}

.c-datepicker__toggle {
  top: 170px;
  width: 36px;
  height: 30px;
  visibility: hidden;
  opacity: 0.5;
  z-index: 1;
  transition: opacity 200ms ease-in-out;
}

.c-datepicker__toggle--right {
  right: 10px;
}

.c-datepicker__toggle--left {
  left: 10px;
}

.c-datepicker__toggle.is-selected {
  opacity: 1;
}

.c-datepicker--show-time.is-selected ~ .c-datepicker__calendar {
  display: none;
}

.c-datepicker--show-calendar.is-selected ~ .c-datepicker__clock {
  display: none;
}

@mixin putOnCircle(
          $nb-items, //Number of items
          $circle-size, //Parent size
          $item-size //Item size
       ) {
  $half-item:     $item-size / 2;
  $half-parent: $circle-size / 2;

  position: relative;               /* [1] */
  width:  $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;                 /* [2] */

  .c-datepicker__clock__num {
    display: block;
    position: absolute;
    top:  50%;
    left: 50%;
    width:  $item-size;
    height: $item-size;
    margin: -$half-item;
    z-index: 98;

    $angle: 360 / $nb-items;
    $rot: 0;
    @for $i from 1 to $nb-items+1 {

      &:nth-of-type(#{$i}) {
        transform: rotate(#{$rot}deg) translate($half-parent) rotate(-#{$rot}deg);
      }

      &:nth-of-type(#{$i}) {
        &.c-datepicker__clock__num--active:not(.hide-hand) {
          ~ .c-datepicker__clock-hands {
            $handRot: $rot + 270;
            transform: translate(-50%, -50%) rotate(#{$handRot}deg);
            .c-datepicker__hour-hand {
              opacity: 1;
              background: $primary;
            }
          }
        }
        &:hover {
          ~ .c-datepicker__clock-hands {
            $handRot: $rot + 270;
            transform: translate(-50%, -50%) rotate(#{$handRot}deg);
            .c-datepicker__hour-hand {
              opacity: 1;
              background: $primary
            }
          }
        }
      }
      $rot: $rot + $angle;
    }
  }
}


.c-datepicker__clock {
  @include putOnCircle(12, 200px, 50px);
  font-size: 14px;
  line-height: 50px;
  padding: 160px 0 20px 0;
  margin: 0 auto;

  &::before {
    content: "";
    position: absolute;
    top: 70px;
    left: -20px;
    width: 240px;
    height: 240px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
  }
}

.u-hover-ball-effect {
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0%;
    height: 0%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition:
      width 100ms ease-in-out,
      height 100ms ease-in-out
  }
  &:hover {
    color: white;
    &:before {
      background: $primary;
      width: 35px;
      height: 35px;
      z-index: -1;
    }
  }
}



.c-datepicker__day-body, .c-datepicker__clock__num {
  @extend .u-hover-ball-effect;

  &--active:not(.hide-hand) {
    color: white;
    &:before {
      background: $primary;
      width: 35px;
      height: 35px;
      z-index: -1;
    }
  }
}

.c-datepicker__clock-hands {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $lowlight;
}

.c-datepicker__hour-hand {
  position: absolute;
  opacity: 0;
  height: 78px;
  width: 2px;
  background: $primary;
  left: 4px;
  top: 10px;
}

.c-datepicker__clock__minutes {
  display: none;
  height: 200px;
  margin: -69px 0 0 0;
  width: 200px;
  display: none;
  &.active {
    display: block;
  }
}

.c-datepicker__clock__hours {
  height: 200px;
  margin: -69px 0 0 0;
  width: 200px;
  display: none;

  &.active {
    display: block;
  }
}

.c-datepicker__mask {
  &:after {
    content: ' ';
    width: 156px;
    height: 70px;
    display: block;
    position: absolute;
    top: 32px;
    left: 0;
    margin-left: -13px;
  }
  &:before {
    content: ' ';
    width: 75px;
    height: 158px;
    display: block;
    position: absolute;
    top: 6px;
    left: 28px;
    margin-top: -18px;
  }
  width: 127px;
  height: 132px;
  position: absolute;
  top: 122px;
  left: 37px;
  z-index: 99;
}

.c-datepicker__clock--show-minutes {
  .c-datepicker__clock__minutes {
    visibility: visible;
  }
  .c-datepicker__clock__hours {
    visibility: hidden;
  }
}

.c-datepicker__clock--show-hours {
  .c-datepicker__clock__minutes {
    visibility: hidden;
  }
  .c-datepicker__clock__hours {
    visibility: visible;
  }
}

.c-datepicker__clock__am-pm-toggle {
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  height: 40px;
  padding: 20px;
  line-height: 40px;

  label {
    width: 40px;
    position: absolute;
    &:nth-child(1) {
      left: 0;
    }
    &:nth-child(2) {
      right: 0;
    }
    @extend .u-hover-ball-effect;

    &.c-datepicker__toggle--checked::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0%;
      height: 0%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      width: 36px;
      height: 36px;
      z-index: -1;
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
