.login-page {
  body {
    @include colour-gradient-animated;
  }
  .fullheight {
    height: 100%; //who would've guessed
  }
  .preloader-wrapper {
    margin: auto;
  }
  @media only screen and (max-width: 600px) {
    .row .valign-wrapper {
      display: inherit;
    }
  }
}